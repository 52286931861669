import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { txIsCanceled, txIsReceived, txIsCompleted, txIsCanceledAfterDispute, TRANSITION_PARTIAL_REFUND_DISPUTED } from '../../util/transaction';
import { LINE_ITEM_REFERRAL_DISCOUNT_PROVIDER, LINE_ITEM_SHIPPING_FEE, propTypes } from '../../util/types';
import { oneOfType } from 'prop-types';

import css from './OrderBreakdown.module.css';

import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;

const LineItemTotalPrice = props => {
  const { transaction, isProvider, intl, listing, orderData, loadingShippingRate } = props;
  const publicData = listing?.attributes?.publicData;

  let providerTotalMessageId = 'OrderBreakdown.providerTotalDefault';
  if (txIsReceived(transaction) || txIsCompleted(transaction)) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalReceived';
  } else if (txIsCanceled(transaction)) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalCanceled';
  }

  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="OrderBreakdown.total" />
  );

  let totalPrice = isProvider
    ? transaction.attributes.payoutTotal
    : transaction.attributes.payinTotal;

  if (txIsCanceled(transaction) && transaction.attributes.lastTransition === TRANSITION_PARTIAL_REFUND_DISPUTED) {
    let total = transaction.attributes.lineItems.reduce((a, b) => {
      if (!isProvider && (b.code === 'line-item/provider-commission' || b.code === 'line-item/payment-processing-fee')) {
        return a;
      }
      else {
        return a + b.lineTotal.amount
      }
    }, 0);
    totalPrice.amount = total;
  }
  if (!txIsCanceled(transaction) && isProvider && !!transaction.attributes.lineItems.find(x => x.code === LINE_ITEM_REFERRAL_DISCOUNT_PROVIDER)) {
    totalPrice = new Money(transaction.attributes.payoutTotal.amount + (0 - transaction.attributes.lineItems.find(x => x.code === LINE_ITEM_REFERRAL_DISCOUNT_PROVIDER).lineTotal.amount), 'USD');
  }
  let formattedTotalPrice = formatMoney(intl, totalPrice);

  const shippingFeeLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_SHIPPING_FEE && !item.reversal
  );

  if (!shippingFeeLineItem && orderData?.deliveryMethod === 'shipping' || loadingShippingRate) {
    formattedTotalPrice = '--'
  }

  return (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>
          <p>{formattedTotalPrice}</p>
          {publicData?.shippingEnabled && publicData?.shippingType !== "custom" && !transaction.attributes.lineItems.map(item => item.code).includes("line-item/pickup-fee") && <p>+ shipping</p>}
        </div>
      </div>
    </>
  );
};

LineItemTotalPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
};

export default LineItemTotalPrice;
