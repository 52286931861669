import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import css from './FavoriteButton.module.css'
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'


const FavoriteButton = ({ clicked, onClick, customClass }) => {
    const [filled, setFilled] = useState(clicked);
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        setFilled(clicked);
    }, [clicked]);

    const handleClicked = () => {
        onClick(!filled);
        setFilled(!filled);
    }

    return (
        <div className={classNames(css.favoriteContainer, customClass)}>
            <FontAwesomeIcon icon={faHeart} className={filled ? classNames(css.heartIcon, css.heartIconColor) : classNames(css.heartIcon)} size="2x" onClick={handleClicked} onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}/>
            {showTooltip && <span className={css.tooltiptext}>{filled ? "Added" : "Add"} to Favorites</span>}
        </div>
    );
};

FavoriteButton.defaultProps = { clicked: false, };

const { bool, func } = PropTypes;

FavoriteButton.propTypes = { clicked: bool, onClick: func };

export default FavoriteButton;
