import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_SHIPPING_FEE, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';
import IconSpinner from '../IconSpinner/IconSpinner';

const LineItemShippingFeeMaybe = props => {
  const { lineItems, intl, orderData, loadingShippingRate } = props;

  const shippingFeeLineItem = lineItems.find(
    item => item.code === LINE_ITEM_SHIPPING_FEE && !item.reversal
  );

  if (shippingFeeLineItem && !loadingShippingRate) {
    return (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="OrderBreakdown.shippingFee" />
        </span>
        <span className={css.itemValue}>{formatMoney(intl, shippingFeeLineItem.lineTotal)}</span>
      </div>
    );
  }
  else if (orderData?.deliveryMethod === 'shipping') {
    return (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="OrderBreakdown.shippingFee" />
        </span>
        <span className={css.itemValue}>{loadingShippingRate ? <IconSpinner rootClassName={css.spinner} /> : "--"}</span>
      </div>
      )
  }
  return null;
};

LineItemShippingFeeMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemShippingFeeMaybe;
