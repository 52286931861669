import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_SALES_TAX_CUSTOMER, LINE_ITEM_SALES_TAX_PROVIDER, LINE_ITEM_SHIPPING_FEE, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';
import IconSpinner from '../IconSpinner/IconSpinner';

const LineItemSalesTaxFeeProviderMaybe = props => {
  const { lineItems, intl, orderData, loadingSalesTax, loadingShippingRate, isCustomer } = props;

  const salesTaxLineItem = isCustomer ? null : lineItems.find(
    item => item.code === LINE_ITEM_SALES_TAX_PROVIDER && !item.reversal
  );

  if (salesTaxLineItem && !loadingSalesTax && salesTaxLineItem.lineTotal.amount !== 0) {
    return (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="OrderBreakdown.salesTax" />
        </span>
        <span className={css.itemValue}>{formatMoney(intl, salesTaxLineItem.lineTotal)}</span>
      </div>
    );
  }
  return null;
};

LineItemSalesTaxFeeProviderMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemSalesTaxFeeProviderMaybe;
