import React, { useState } from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import css from './SelectOrderTypeComponent.module.css';
import IconArrowHead from '../../../IconArrowHead/IconArrowHead';
import FieldCurrencyInput from '../../../FieldCurrencyInput/FieldCurrencyInput';
import config from '../../../../config';

const SelectOrderTypeComponent = props => {
    const {
        rootClassName,
        className,
        svgClassName,
        textClassName,
        id,
        label,
        buyNowBreakdown,
        makeOfferBreakdown,
        useSuccessColor,
        displayMakeOffer,
        intl,
        ...rest
    } = props;

    const [buyNow, setBuyNow] = useState(false);
    const [makeAnOffer, setMakeAnOffer] = useState(false);

    const classes = classNames(rootClassName || css.root, className);

    // This is a workaround for a bug in Firefox & React Final Form.
    // https://github.com/final-form/react-final-form/issues/134
    const handleOnChange = (input) => {
        setBuyNow(input.includes("buyNow"));
        setMakeAnOffer(input.includes("makeAnOffer"));
    };

    return (
        <span className={classes}>

            <Accordion className={css.accordion} onChange={handleOnChange} allowMultipleExpanded allowZeroExpanded>
                <AccordionItem uuid="buyNow">
                    <AccordionItemHeading className={css.accordionHeading}>
                        <AccordionItemButton className={css.accordionBox}>
                            <p>Buy now</p>
                            <IconArrowHead direction={buyNow ? "up" : "down"} size="big" className={css.downArrow} />
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        {buyNowBreakdown}
                    </AccordionItemPanel>
                </AccordionItem>

                <hr className={css.divider}></hr>
                <AccordionItem uuid="makeAnOffer">
                    <AccordionItemHeading className={css.accordionHeading}>
                        <AccordionItemButton className={css.accordionBox}>
                            <p>Make an offer</p>
                            <IconArrowHead direction={makeAnOffer ? "up" : "down"} size="big" className={css.downArrow} />
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        {displayMakeOffer && <><p>
                            Make an offer on this item. The seller will have 24 hours to accept or deny the offer.
                        </p>
                            <FieldCurrencyInput
                                id={"offer"}
                                className={css.quantityField}
                                name="offer"
                                label={"Offer"}
                                placeholder={"Enter a number"}
                                currencyConfig={config.currencyConfig}
                            />
                        </>}
                        {makeOfferBreakdown}
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        </span>
    );
};

SelectOrderTypeComponent.defaultProps = {
    className: null,
    rootClassName: null,
    svgClassName: null,
    textClassName: null,
    label: null,
};

SelectOrderTypeComponent.propTypes = {
    className: string,
    rootClassName: string,
    svgClassName: string,
    textClassName: string,

    // // Id is needed to connect the label with input.
    // id: string.isRequired,
    // label: node,

    // // Name groups several checkboxes to an array of selected values
    // name: string.isRequired,

    // // Checkbox needs a value that is passed forward when user checks the checkbox
    // value: string.isRequired,
};

export default SelectOrderTypeComponent;
