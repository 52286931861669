import React, { Component } from 'react';
import { string, func, bool } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';
import FavoriteButton from '../FavoriteButton/FavoriteButton';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { updateFavorite, updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    currentUser,
    onUpdateProfile,
    onUpdateFavorites,
    alertParentOfFavorite,
    alertParentOfFavoriteFunc,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const { formattedPrice, priceTitle } = priceData(price, intl);
  const unitType = config.lineItemUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
      ? 'ListingCard.perDay'
      : 'ListingCard.perUnit';

  const setActivePropsMaybe = setActiveListing
    ? {
      onMouseEnter: () => setActiveListing(currentListing.id),
      onMouseLeave: () => setActiveListing(null),
    }
    : null;

  const handleClickFavorite = async (clicked) => {
    let favorites = currentUser.attributes?.profile?.protectedData?.listingFavorites?.map(fav => JSON.parse(fav));
    if (!favorites) {
      favorites = []
    }
    const favoriteIds = favorites.map(fav => fav.id.uuid);
    const id = currentListing.id.uuid;

    if (favoriteIds.includes(id) && !clicked) {
      favorites.splice(favoriteIds.indexOf(id), 1);
    }
    else if (!favoriteIds.includes(id) && clicked) {
      favorites.push(listing);
    }
    await onUpdateProfile({ protectedData: { listingFavorites: favorites.map(fav => JSON.stringify(fav)) } });
    await onUpdateFavorites(currentUser.attributes.email + "|" + authorName, currentListing.id, clicked);
    if (alertParentOfFavorite) {
      alertParentOfFavoriteFunc(id);
    }
  }

  return (
    <div className={classes}>
      <NamedLink name="ListingPage" params={{ id, slug }}>
        <AspectRatioWrapper
          className={css.aspectRatioWrapper}
          width={aspectWidth}
          height={aspectHeight}
          {...setActivePropsMaybe}
        >
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={variants}
            sizes={renderSizes}
          />
        </AspectRatioWrapper>
      </NamedLink>
      <div className={css.info}>
        <NamedLink className={css.notLink} name="ListingPage" params={{ id, slug }}>
          <div className={css.price}>
            <div className={css.priceValue} title={priceTitle}>
              {formattedPrice}
            </div>
            {config.listing.showUnitTypeTranslations ? (
              <div className={css.perUnit}>
                <FormattedMessage id={unitTranslationKey} />
              </div>
            ) : null}
          </div>
        </NamedLink>
        <div className={css.mainInfo}>
          <div className={css.title}>
            <div className={css.titleAndHeart}>
              <NamedLink className={css.notLink} name="ListingPage" params={{ id, slug }}>
                {richText(title, {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                })}
              </NamedLink>
              {!!currentUser && currentUser?.id?.uuid !== listing?.author?.id?.uuid && <FavoriteButton customClass={css.favoriteButton} clicked={currentUser?.attributes?.profile?.protectedData?.listingFavorites?.map(fav => JSON.parse(fav).id.uuid)?.includes(listing.id.uuid)} onClick={(clicked) => handleClickFavorite(clicked)}></FavoriteButton>}
            </div>
          </div>
          {showAuthorInfo ? (
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.author" values={{ authorName }} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
  alertParentOfFavorite: false,
  alertParentOfFavoriteFunc: () => {}
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
  currentUser: propTypes.currentUser,

  alertParentOfFavorite: propTypes.bool,
  alertParentOfFavoriteFunc: propTypes.func
};

export default injectIntl(ListingCardComponent);