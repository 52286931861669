import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { ExternalLink } from '..';
import classNames from 'classnames';

import css from './PromotionBanner.module.css';

class PromotionBanner extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
    this.onClosePromotion = this.onClosePromotion.bind(this);
    this.savePromotion = this.savePromotion.bind(this);
  }

  componentDidMount() {
    const promotionStartDate = new Date(parseInt(process.env.REACT_APP_PROMOTION_START));
    const promotionEndDate = new Date(parseInt(process.env.REACT_APP_PROMOTION_END));
    const now = new Date();
    const promotion = document.cookie.split('; ').reduce((acc, c) => {
      const [name, value] = c.split('=');
      return { ...acc, [name]: decodeURIComponent(value) };
    }, {});
    if (now >= promotionStartDate && now <= promotionEndDate && promotion.closedPromotion !== '1') {
      this.setState({ show: true });
    }
  }

  onClosePromotion() {
    this.savePromotion();
    this.setState({ show: false });
    this.props.onPageClosePromotion();
  }

  savePromotion() {
    let expirationDate = new Date();
    expirationDate.setHours(expirationDate.getMinutes() + 30);
    // Save the cookie with expiration date
    document.cookie = 'closedPromotion=1; path=/; expires=' + expirationDate.toGMTString();
  }

  render() {
    const { className, rootClassName } = this.props;
    const isServer = typeof window === 'undefined';

    // Server side doesn't know about cookie consent
    if (isServer || !this.state.show) {
      return null;
    } else {
      const cookieLink = (
        <ExternalLink href="https://cookiesandyou.com" className={css.cookieLink}>
          <FormattedMessage id="CookieConsent.cookieLink" />
        </ExternalLink>
      );
      const classes = classNames(rootClassName || css.root, className);

      return (
        <div className={classes}>
          <div className={css.message}>
            {process.env.REACT_APP_PROMOTION_TEXT}
          </div>
          <button className={css.continueBtn} onClick={this.onClosePromotion}>
            X
          </button>
        </div>
      );
    }
  }
}

const { string } = PropTypes;

PromotionBanner.defaultProps = {
  className: null,
  rootClassName: null,
};

PromotionBanner.propTypes = {
  className: string,
  rootClassName: string,
};

export default PromotionBanner;
