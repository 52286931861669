import React, { createRef, forwardRef, useEffect, useRef, useState } from 'react';
import { bool, func, number, string, oneOfType } from 'prop-types';
import { Form as FinalForm, FormSpy } from 'react-final-form';

import config from '../../../config';
import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { numberAtLeast, required } from '../../../util/validators';

import {
  Form,
  FieldSelect,
  FieldTextInput,
  InlineTextButton,
  PrimaryButton,
  FieldCheckbox,
  Button,
  FieldCurrencyInput,
} from '../../../components';


import css from './DropPriceForm.module.css';

import { types as sdkTypes } from '../../../util/sdkLoader';
import EstimatedCustomerBreakdownMaybe from '../../../components/OrderPanel/EstimatedCustomerBreakdownMaybe';
import SelectOrderTypeComponent from '../../../components/OrderPanel/ProductOrderForm/SelectOrderType/SelectOrderType';
import classNames from 'classnames';
import { formatMoney } from '../../../util/currency';
const { Money } = sdkTypes;

const renderForm = formRenderProps => {
  const {
    // FormRenderProps from final-form
    handleSubmit,
    form: formApi,

    // Custom props passed to the form component
    intl,
    formId,
    currentStock,
    hasMultipleDeliveryMethods,
    listing,
    listingId,
    isOwnListing,
    onFetchTransactionLineItems,
    onContactUser,
    currentUser,
    lineItems,
    updateInProgress,
    updateListingError,
    values,
    setBuyOffer,
    buyOffer,
    submitFunction,
    setModalOpen,
    selectedPercentage,
    setSelectedPercentage,
    price,
    setSelectedNewPrice,
    selectedNewPrice
  } = formRenderProps;

  const handleOnChange = newPrice => {
    if (newPrice === null) {
      newPrice = new Money(0, 'USD');
    }

    setSelectedNewPrice(newPrice);
    if (newPrice.amount === price.amount * .9) {
      setSelectedPercentage("10");
    }
    else if (newPrice.amount === price.amount * .85) {
      setSelectedPercentage("15");
    }
    else {
      setSelectedPercentage("Other");
    }
  };

  // In case quantity and deliveryMethod are missing focus on that select-input.
  // Otherwise continue with the default handleSubmit function.
  const handleFormSubmit = e => {
    e.preventDefault();
    submitFunction(formRenderProps.values);
  };


  const breakdownData = {};
  const showBreakdown =
    breakdownData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;
  const breakdown = showBreakdown ? (
    <div className={css.breakdownWrapper}>
      <p>Buy this item now for the price outlined below.</p>
      <h3>
        <FormattedMessage id="ProductOrderForm.breakdownTitle" />
      </h3>
      <EstimatedCustomerBreakdownMaybe
        unitType={config.lineItemUnitType}
        breakdownData={breakdownData}
        lineItems={lineItems}
        listing={listing}
      />
    </div>
  ) : null;

  const makeOfferBreakdown = (
    <div className={css.breakdownWrapper}>
      <h3>
        <FormattedMessage id="ProductOrderForm.breakdownTitle" />
      </h3>
      <EstimatedCustomerBreakdownMaybe
        unitType={config.lineItemUnitType}
        breakdownData={breakdownData}
        lineItems={!!lineItems && lineItems.map(x => {
          if (x.code === "line-item/units" && !!formRenderProps?.values?.offer) {
            return { ...x, lineTotal: new Money(formRenderProps.values.offer.amount * x.quantity.s, x.lineTotal.currency) };
          }
          return x;
        })}
        listing={listing}
      />
    </div>
  );

  const showContactUser = typeof onContactUser === 'function';

  const onClickContactUser = e => {
    e.preventDefault();
    onContactUser();
  };

  const contactSellerLink = (
    <InlineTextButton onClick={onClickContactUser}>
      <FormattedMessage id="ProductOrderForm.finePrintNoStockLinkText" />
    </InlineTextButton>
  );
  const quantityRequiredMsg = intl.formatMessage({ id: 'ProductOrderForm.quantityRequired' });

  const hasStock = currentStock && currentStock > 0;
  const quantities = hasStock ? [...Array(currentStock).keys()].map(i => i + 1) : [];
  const hasNoStockLeft = typeof currentStock != null && currentStock === 0;
  const hasOneItemLeft = typeof currentStock != null && currentStock === 1;

  const submitInProgress = updateInProgress;
  const submitEnabled = hasStock && !!formRenderProps?.values?.quantity && !!formRenderProps?.values?.deliveryMethod;

  return (
    <Form onSubmit={handleFormSubmit}>
      <h2 className={css.formTitlePriceDrop}>Drop Price By</h2>

      <div className={css.priceDropButtonGroup}>
        <Button
          className={classNames(css.priceDropButtonOption, selectedPercentage === "10" && css.selectedPriceDropButton)}
          onClick={(e) => { e.preventDefault(); setSelectedPercentage("10"); setSelectedNewPrice(new Money(price.amount * .9, 'USD')); formApi.mutators.setNewPrice(); }}>
          10%
        </Button>
        <Button
          className={classNames(css.priceDropButtonOption, selectedPercentage === "15" && css.selectedPriceDropButton)}
          onClick={(e) => { e.preventDefault(); setSelectedPercentage("15"); setSelectedNewPrice(new Money(price.amount * .85, 'USD')); formApi.mutators.setNewPrice(); }}>
          15%
        </Button>
        <Button
          className={classNames(css.priceDropButtonOption, selectedPercentage === "Other" && css.selectedPriceDropButton)}
          onClick={(e) => { e.preventDefault(); setSelectedPercentage("Other") }}>
          Other
        </Button>
      </div>

      <p className={css.explanationText}>Dropping your price by at least 10% will notify users who have favorited your item.</p>

      <p className={css.currentPriceDisplay}>Current price: {formatMoney(intl, price)}</p>
      <div className={css.newPriceInput}>
        <p>New price: </p>
        <FieldCurrencyInput
          id={"newPrice"}
          className={css.quantityField}
          name="newPrice"
          placeholder={"Enter a price"}
          currencyConfig={config.currencyConfig}
          onChange={(e) => handleOnChange(e)}
          currentPrice={selectedNewPrice}
          maxPrice={price}
        />
      </div>

      {updateListingError && <p className={css.errorText}>There was an error while dropping the price of this item. Please refresh and try again.</p>}
      <PrimaryButton type="submit" className={css.dropPriceButton} inProgress={updateInProgress} disabled={values?.newPrice?.amount === 0} onClick={() => setBuyOffer(true)}>
        Drop Price
      </PrimaryButton>
    </Form>
  );
};

const DropPriceForm = props => {
  const intl = useIntl();
  const { price, currentStock, pickupEnabled, shippingEnabled, onSubmit, listing, updateInProgress } = props;

  const [selectedPercentage, setSelectedPercentage] = useState("10");
  const [selectedNewPrice, setSelectedNewPrice] = useState(new Money(price.amount * .9, 'USD'));

  const [buyOffer, setBuyOffer] = useState();

  useEffect(() => {
    setSelectedNewPrice(new Money(price.amount * .9, 'USD'));
    setSelectedPercentage("10");
  }, [price]);

  // Should not happen for listings that go through EditListingWizard.
  // However, this might happen for imported listings.
  if (!pickupEnabled && !shippingEnabled) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.noDeliveryMethodSet" />
      </p>
    );
  }

  if (!price) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.listingPriceMissing" />
      </p>
    );
  }
  if (price.currency !== config.currency) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.listingCurrencyInvalid" />
      </p>
    );
  }
  const hasOneItemLeft = currentStock && currentStock === 1;
  const quantityMaybe = hasOneItemLeft ? { quantity: '1' } : {};
  const singleDeliveryMethodAvailableMaybe =
    shippingEnabled && !pickupEnabled
      ? { deliveryMethod: 'shipping' }
      : !shippingEnabled && pickupEnabled
        ? { deliveryMethod: 'pickup' }
        : {};
  const hasMultipleDeliveryMethods = pickupEnabled && shippingEnabled;

  const initialValues = { newPrice: selectedNewPrice };

  return (
    <FinalForm
      initialValues={initialValues}
      hasMultipleDeliveryMethods={hasMultipleDeliveryMethods}
      {...props}
      intl={intl}
      render={renderForm}
      setBuyOffer={setBuyOffer}
      buyOffer={buyOffer}
      submitFunction={onSubmit}
      selectedPercentage={selectedPercentage}
      setSelectedPercentage={setSelectedPercentage}
      selectedNewPrice={selectedNewPrice}
      setSelectedNewPrice={setSelectedNewPrice}
      mutators={{ 
        setNewPrice: (args, state, utils) => {
          utils.changeValue(state, 'newPrice', () => new Money(1000, 'USD'))
        }
      }}
    />
  );
};

DropPriceForm.defaultProps = {
  rootClassName: null,
  className: null,
  price: null,
  currentStock: null,
  listing: null,
  listingId: null,
};

DropPriceForm.propTypes = {
  rootClassName: string,
  className: string,

  // form
  formId: string.isRequired,
  onSubmit: func.isRequired,

  // listing
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  listingId: propTypes.uuid,
  price: propTypes.money,
  currentStock: number,
};

export default DropPriceForm;
