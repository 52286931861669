import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_REFERRAL_DISCOUNT, LINE_ITEM_REFERRAL_DISCOUNT_PROVIDER, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemProviderReferralDiscountMaybe = props => {
    const { lineItems, intl, isProvider } = props;

    const referralDiscountLineItem = lineItems.find(
        item => item.code === LINE_ITEM_REFERRAL_DISCOUNT_PROVIDER
    );

    return referralDiscountLineItem && isProvider ? (
        <div className={css.lineItem}>
            <span className={css.itemLabel}>
                Referral Discount reimbursement
            </span>
            <span className={css.itemValue}>{formatMoney(intl, referralDiscountLineItem.lineTotal)}</span>
        </div>
    ) : null;
};

LineItemProviderReferralDiscountMaybe.propTypes = {
    lineItems: propTypes.lineItems.isRequired,
    intl: intlShape.isRequired,
};

export default LineItemProviderReferralDiscountMaybe;
