// Add here the translations of the country names using key "<language_code>: 'transalation'" e.g. fi: 'Afganistan'
// prettier-ignore
const stateCodes = [
    { code: 'AZ', en: 'Arizona' },
    { code: 'AL', en: 'Alabama' },
    { code: 'AK', en: 'Alaska' },
    { code: 'AR', en: 'Arkansas' },
    { code: 'CA', en: 'California' },
    { code: 'CO', en: 'Colorado' },
    { code: 'CT', en: 'Connecticut' },
    { code: 'DE', en: 'Delaware' },
    { code: 'FL', en: 'Florida' },
    { code: 'GA', en: 'Georgia' },
    { code: 'HI', en: 'Hawaii' },
    { code: 'ID', en: 'Idaho' },
    { code: 'IL', en: 'Illinois' },
    { code: 'IN', en: 'Indiana' },
    { code: 'IA', en: 'Iowa' },
    { code: 'KS', en: 'Kansas' },
    { code: 'KY', en: 'Kentucky' },
    { code: 'LA', en: 'Louisiana' },
    { code: 'ME', en: 'Maine' },
    { code: 'MD', en: 'Maryland' },
    { code: 'MA', en: 'Massachusetts' },
    { code: 'MI', en: 'Michigan' },
    { code: 'MN', en: 'Minnesota' },
    { code: 'MS', en: 'Mississippi' },
    { code: 'MO', en: 'Missouri' },
    { code: 'MT', en: 'Montana' },
    { code: 'NE', en: 'Nebraska' },
    { code: 'NV', en: 'Nevada' },
    { code: 'NH', en: 'New Hampshire' },
    { code: 'NJ', en: 'New Jersey' },
    { code: 'NM', en: 'New Mexico' },
    { code: 'NY', en: 'New York' },
    { code: 'NC', en: 'North Carolina' },
    { code: 'ND', en: 'North Dakota' },
    { code: 'OH', en: 'Ohio' },
    { code: 'OK', en: 'Oklahoma' },
    { code: 'OR', en: 'Oregon' },
    { code: 'PA', en: 'Pennsylvania' },
    { code: 'RI', en: 'Rhode Island' },
    { code: 'SC', en: 'South Carolina' },
    { code: 'SD', en: 'South Dakota' },
    { code: 'TN', en: 'Tennessee' },
    { code: 'TX', en: 'Texas' },
    { code: 'UT', en: 'Utah' },
    { code: 'VT', en: 'Vermont' },
    { code: 'VA', en: 'Virginia' },
    { code: 'WA', en: 'Washington' },
    { code: 'WV', en: 'West Virginia' },
    { code: 'WI', en: 'Wisconsin' },
    { code: 'WY', en: 'Wyoming' },
  ];
  
  const getStateCodes = lang => {
    // Add the lnew locale here so that the correct translations will be returned.
    // If locale is unknown or the translation is missing, this will default to english coutnry name.
    if (lang !== 'en') {
      return [];
    }
    const codes = stateCodes.map(c => {
      const stateName = c[lang] ? c[lang] : c['en'];
      const stateCode = c.code;
  
      return { code: stateCode, name: stateName };
    });
    return codes;
  };
  
  export default getStateCodes;
  