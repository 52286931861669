import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_SALES_TAX_CUSTOMER, LINE_ITEM_SALES_TAX_PROVIDER, LINE_ITEM_SHIPPING_FEE, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';
import IconSpinner from '../IconSpinner/IconSpinner';

const LineItemSalesTaxFeeMaybe = props => {
  const { lineItems, intl, loadingSalesTax, loadingShippingRate, isCancelled } = props;

  const salesTaxLineItem = lineItems.find(
    item => item.code === LINE_ITEM_SALES_TAX_CUSTOMER && !item.reversal
  );

  if (salesTaxLineItem && !loadingSalesTax) {
    return (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="OrderBreakdown.salesTax" />
        </span>
        <span className={css.itemValue}>{formatMoney(intl, salesTaxLineItem.lineTotal)}</span>
      </div>
    );
  }
  else if (!isCancelled) {
    return (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="OrderBreakdown.salesTax" />
        </span>
        <span className={css.itemValue}>{(loadingSalesTax || loadingShippingRate) ? <IconSpinner rootClassName={css.spinner} /> : "--"}</span>
      </div>
      )
  }
  return null;
};

LineItemSalesTaxFeeMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemSalesTaxFeeMaybe;
