import pick from 'lodash/pick';
import config from '../../config';
import { getSalesTax, getShippingLabel, getShippingRates, indicateUserMadeOfferRequest, initiatePrivileged, logSalesTaxTransaction, postUpdateReferralInfo, transitionPrivileged } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import {
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_CONFIRM_PAYMENT,
  isPrivileged,
  TRANSITION_MAKE_OFFER,
  TRANSITION_UPDATE_OFFERED_METADATA,
  txIsEnquired,
  TRANSITION_MAKE_OFFER_AFTER_ENQUIRY,
} from '../../util/transaction';
import * as log from '../../util/log';
import { fetchCurrentUserHasOrdersSuccess, fetchCurrentUser } from '../../ducks/user.duck';

import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/ListingPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/ListingPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/ListingPage/SPECULATE_TRANSACTION_ERROR';

export const TRANSACTION_LABEL_REQUEST = 'app/ListingPage/TRANSACTION_LABEL_REQUEST';
export const TRANSACTION_LABEL_SUCCESS = 'app/ListingPage/TRANSACTION_LABEL_SUCCESS';
export const TRANSACTION_LABEL_ERROR = 'app/ListingPage/TRANSACTION_LABEL_ERROR';


export const UPDATE_REFERRAL_INFO_REQUEST = 'app/ListingPage/UPDATE_REFERRAL_INFO_REQUEST';
export const UPDATE_REFERRAL_INFO_SUCCESS = 'app/ListingPage/UPDATE_REFERRAL_INFO_SUCCESS';
export const UPDATE_REFERRAL_INFO_ERROR = 'app/ListingPage/UPDATE_REFERRAL_INFO_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/STRIPE_CUSTOMER_ERROR';

export const SHIPPING_RATES_REQUEST = 'app/CheckoutPage/SHIPPING_RATES_REQUEST';
export const SHIPPING_RATES_SUCCESS = 'app/CheckoutPage/SHIPPING_RATES_SUCCESS';
export const SHIPPING_RATES_ERROR = 'app/CheckoutPage/SHIPPING_RATES_ERROR';

export const SALES_TAX_REQUEST = 'app/CheckoutPage/SALES_TAX_REQUEST';
export const SALES_TAX_SUCCESS = 'app/CheckoutPage/SALES_TAX_SUCCESS';
export const SALES_TAX_ERROR = 'app/CheckoutPage/SALES_TAX_ERROR';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  offer: null,
  orderData: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  stripeCustomerFetched: false,
  shippingRates: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };

    case TRANSACTION_LABEL_REQUEST:
      return { ...state, transactionLabelFetched: false, transactionLabelError: false }
    case TRANSACTION_LABEL_SUCCESS:
      return { ...state, transactionLabelFetched: true }
    case TRANSACTION_LABEL_ERROR:
      return { ...state, transactionLabelFetched: false, transactionLabelError: true }

    case UPDATE_REFERRAL_INFO_REQUEST:
      return { ...state, updateReferralInfoCompleted: false }
    case UPDATE_REFERRAL_INFO_SUCCESS:
      return { ...state, updateReferralInfoCompleted: true }


    case SALES_TAX_REQUEST:
      return { ...state, salesTaxInProgress: true, salesTaxError: false };


    case SALES_TAX_SUCCESS:
      let newSpeculatedTransaction = { ...state.speculatedTransaction };
      let taxAmount = payload.taxCalculated * 100;
      newSpeculatedTransaction.attributes.lineItems = newSpeculatedTransaction.attributes.lineItems.map(x => {
        if (x.code === 'line-item/sales-tax-customer') {
          return {
            ...x,
            unitPrice: new Money(taxAmount, 'USD'),
            lineTotal: new Money(taxAmount, 'USD'),
          }
        }
        else if (x.code === 'line-item/sales-tax-provider') {
          return {
            ...x,
            unitPrice: new Money(0 - taxAmount, 'USD'),
            lineTotal: new Money(0 - taxAmount, 'USD'),
          }
        }
        else {
          return x;
        }
      });

      const newPayin = newSpeculatedTransaction.attributes.lineItems.reduce((a, b) => {
        if (b.includeFor.includes('customer')) {
          return a + b.lineTotal.amount;
        }
        else {
          return a;
        }
      }, 0);
      const newPayout = newSpeculatedTransaction.attributes.lineItems.reduce((a, b) => {
        if (b.includeFor.includes('provider')) {
          return a + b.lineTotal.amount;
        }
        else {
          return a;
        }
      }, 0);
      newSpeculatedTransaction.attributes.payinTotal = new Money(newPayin, 'USD');
      newSpeculatedTransaction.attributes.payoutTotal = new Money(newPayout, 'USD');
      return { ...state, speculatedTransaction: newSpeculatedTransaction, salesTaxFetched: true, salesTaxInProgress: false, salesTaxError: false, tax: payload };

    case SALES_TAX_ERROR:
      return { ...state, salesTaxFetched: true, salesTaxInProgress: false, salesTaxError: true };

    case SHIPPING_RATES_REQUEST:
      return { ...state, shippingRatesFetched: false, shippingRatesInProgress: true, shippingRates: {}, shippingRatesError: false };
    case SHIPPING_RATES_SUCCESS:
      let tx = state.speculatedTransaction;

      const newShippingPrice = payload.shipping_amount.amount * 100;
      const units = tx.attributes.lineItems.find(item => item.code === 'line-item/units');
      const lineItem = {
        ...units,
        code: 'line-item/shipping-fee',
        lineTotal: new Money(newShippingPrice, 'USD'),
        unitPrice: new Money(newShippingPrice, 'USD'),
      };
      if (!!tx.attributes.lineItems.find(x => x.code === 'line-item/shipping-fee')) {
        tx.attributes.lineItems = tx.attributes.lineItems.map(x => {
          if (x.code === 'line-item/shipping-fee') {
            return lineItem;
          }
          return x;
        })
      }
      else {
        tx.attributes.lineItems.push(lineItem);
      }

      const payin = tx.attributes.lineItems.reduce((a, b) => {
        if (b.includeFor.includes('customer')) {
          return a + b.lineTotal.amount;
        }
        else {
          return a;
        }
      }, 0);
      const payout = tx.attributes.lineItems.reduce((a, b) => {
        if (b.includeFor.includes('provider')) {
          return a + b.lineTotal.amount;
        }
        else {
          return a;
        }
      }, 0);
      tx.attributes.payinTotal = new Money(payin, 'USD');
      tx.attributes.payoutTotal = new Money(payout, 'USD');
      return { ...state, shippingRates: payload, shippingRatesFetched: true, shippingRatesInProgress: false, speculatedTransaction: tx }
    case SHIPPING_RATES_ERROR:
      return { ...state, shippingRatesInProgress: false, shippingRatesError: true, shippingRates: {} }
    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const transactionLabelRequest = () => ({ type: TRANSACTION_LABEL_REQUEST });

export const updateReferralInfoRequest = () => ({ type: UPDATE_REFERRAL_INFO_REQUEST });
export const updateReferralInfoSuccess = t => ({
  type: UPDATE_REFERRAL_INFO_SUCCESS,
  payload: { transaction: t }
});
export const updateReferralInfoError = e => ({
  type: UPDATE_REFERRAL_INFO_ERROR,
  error: true,
  payload: e,
});

export const transactionLabelSuccess = transaction => ({
  type: TRANSACTION_LABEL_SUCCESS,
  payload: { transaction },
});

export const transactionLabelError = e => ({
  type: TRANSACTION_LABEL_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

export const shippingRatesRequest = () => ({ type: SHIPPING_RATES_REQUEST });
export const shippingRatesSuccess = (rates) => ({ type: SHIPPING_RATES_SUCCESS, payload: rates });
export const shippingRatesFailed = (rates) => ({ type: SHIPPING_RATES_ERROR });

export const salesTaxRequest = () => ({ type: SALES_TAX_REQUEST });
export const salesTaxSuccess = (rates) => ({ type: SALES_TAX_SUCCESS, payload: rates });
export const salesTaxError = (rates) => ({ type: SALES_TAX_ERROR });

/* ================ Thunks ================ */

export const initiateOrder = (orderParams, transactionId, shippingLineItem, offer, isEnquired, userId) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());

  let tax = getState().CheckoutPage.tax;

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  let transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_REQUEST_PAYMENT;

  if (!!offer) {
    if (isEnquired) {
      transition = TRANSITION_MAKE_OFFER_AFTER_ENQUIRY;
    }
    else {
      transition = TRANSITION_MAKE_OFFER;
    }
  }

  const isPrivilegedTransition = isPrivileged(transition);

  const { deliveryMethod, quantity, bookingDates, ...otherOrderParams } = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  const bookingParamsMaybe = bookingDates || {};

  // Parameters only for client app's server
  const orderData = {
    deliveryMethod,
  };

  // Parameters for Flex API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingParamsMaybe,
    ...otherOrderParams,
  };

  const bodyParams = isTransition
    ? {
      id: transactionId,
      transition,
      params: transitionParams,
    }
    : {
      processAlias: config.transactionProcessAlias,
      transition,
      params: transitionParams,
    };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSucces = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    throw e;
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: false, orderData, bodyParams, queryParams, shippingLineItem, offer, userId, tax })
      .then(handleSucces)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: false, orderData, bodyParams, queryParams, shippingLineItem, offer, userId, tax })
      .then(handleSucces)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiate(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  }
};

export const confirmPayment = orderParams => (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());

  const bodyParams = {
    id: orderParams.transactionId,
    transition: TRANSITION_CONFIRM_PAYMENT,
    params: {
      protectedData: {
        paymentIntentId: orderParams.paymentIntentId
      }
    },
  };

  return sdk.transactions
    .transition(bodyParams)
    .then(response => {
      const order = response.data.data;
      dispatch(confirmPaymentSuccess(order.id));
      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true, ...params };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false, ...params };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true, ...params });
  }
};

/**
 * Initiate or transition the speculative transaction with the given
 * booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (orderParams, transactionId, offer, userId) => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);

  const { deliveryMethod, quantity, bookingDates, ...otherOrderParams } = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  const bookingParamsMaybe = bookingDates || {};

  // Parameters only for client app's server
  const orderData = {
    deliveryMethod,
  };

  // Parameters for Flex API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingParamsMaybe,
    ...otherOrderParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };

  const bodyParams = isTransition
    ? {
      id: transactionId,
      transition,
      params: transitionParams,
    }
    : {
      processAlias: config.transactionProcessAlias,
      transition,
      params: transitionParams,
    };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = e => {
    log.error(e, 'speculate-transaction-failed', {
      listingId: transitionParams.listingId.uuid,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: true, orderData, bodyParams, queryParams, offer, userId })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: true, orderData, bodyParams, queryParams, offer, userId })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiateSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

export const updateTransactionMetaData = (fnParams, transactionId, address, referredBy, customerEmail) => (dispatch, getState, sdk) => {
  return sdk.transactions.transition({
    id: transactionId,
    transition: TRANSITION_UPDATE_OFFERED_METADATA,
    params: { protectedData: { paymentIntent: JSON.stringify(fnParams.paymentIntent), paymentIntentId: fnParams.paymentIntentId, address: JSON.stringify(address), referredBy, customerEmail } },
  }).then(() => {
    return fnParams;
  })
}

export const transactionLabel = (fnParams, toAddress, fromAddress, phoneNumber, displayName, productWeight, height, width, length, amount) => (dispatch, getState, sdk) => {
  dispatch(transactionLabelRequest());

  return getShippingLabel(toAddress, fromAddress, phoneNumber, displayName, productWeight, height, width, length, amount).then(label => {
    return sdk.transactions.transition({
      id: fnParams.orderId,
      transition: "transition/add-shipping-label",
      params: { protectedData: { shippingLabel: label.pdf, packageLink: label.packageLink, initialShipTrackingNumber: label.initialShipTrackingNumber } }
    }).then(() => {
      return { ...fnParams, label }
    })
  })
};

export const transactionOfferLabel = (fnParams, toAddress, fromAddress, phoneNumber, displayName, productWeight, height, width, length, amount) => (dispatch, getState, sdk) => {
  dispatch(transactionLabelRequest());

  return getShippingLabel(toAddress, fromAddress, phoneNumber, displayName, productWeight, height, width, length, amount).then(label => {
    return sdk.transactions.transition({
      id: fnParams.orderId,
      transition: "transition/add-shipping-label-after-offer",
      params: { protectedData: { shippingLabel: label.pdf, packageLink: label.packageLink, initialShipTrackingNumber: label.initialShipTrackingNumber, reimbursementPaymentId: !!fnParams.reimbursementPaymentId ? fnParams.reimbursementPaymentId : null } }
    }).then(() => {
      return { ...fnParams, label }
    })
  })
};

export const generateNewShippingLabel = (toAddress, fromAddress, phoneNumber, displayName, productWeight, height, width, length, amount) => (dispatch, getState, sdk) => {
  dispatch(transactionLabelRequest());
  
  return getShippingLabel(toAddress, fromAddress, phoneNumber, displayName, productWeight, height, width, length, amount).then(label => {
    dispatch(transactionLabelSuccess());
    return label;
  }).catch(e => {
    dispatch(transactionLabelError());
    return e;
  })
};

export const shippingLabelTransition = (fnParams, protectedData, label) => (dispatch, getState, sdk) => {
  dispatch(transactionLabelRequest());

  return sdk.transactions.transition({
    id: fnParams.orderId,
    transition: "transition/add-shipping-label",
    params: { protectedData }
  }).then(() => {
    return { ...fnParams, label }
  })
}

export const updateReferralInfo = (fnParams, currentUserId, transactionId, referralCodeUsed) => (dispatch, getState, sdk) => {
  dispatch(updateReferralInfoRequest());

  return postUpdateReferralInfo(currentUserId, transactionId, referralCodeUsed).then(() => {
    return fnParams;
  });
}

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};


export const fetchShippingRates = (toAddress, fromAddress, phone, name, weight, height, width, length, amount) => (dispatch, getState, sdk) => {
  dispatch(shippingRatesRequest());

  return getShippingRates(toAddress, fromAddress, phone, name, weight, height, width, length, amount).then((response) => {
    dispatch(shippingRatesSuccess(response));
    return response;
  }).catch(e => {
    console.log(e);
    dispatch(shippingRatesFailed());
  })
}

export const getSalesTaxRequest = (id, customerAddress, providerAddress, lineItems, listingCategory, listingDescription) => (dispatch, getState, sdk) => {
  dispatch(salesTaxRequest());

  return getSalesTax(id, customerAddress, providerAddress, lineItems, listingCategory, listingDescription).then(tax => {
    dispatch(salesTaxSuccess(tax));
  }).catch(e => {
    console.log(e);
    dispatch(salesTaxError());
  });
}

export const logSalesTaxTransactionRequest = (id, customerId, customerAddress, providerAddress, listingCategory, listingDescription) => (dispatch, getState, sdk) => {
  dispatch(salesTaxRequest());

  return logSalesTaxTransaction(id, customerId, customerAddress, providerAddress, listingCategory, listingDescription).then(tax => {
    return dispatch(salesTaxSuccess(tax));
  }).catch(e => {
    console.log(e);
    dispatch(salesTaxError());
  });
}