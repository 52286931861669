// These helpers are calling FTW's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import { types as sdkTypes, transit } from './sdkLoader';
import config from '../config';
import Decimal from 'decimal.js';
import emailjs from '@emailjs/browser';

export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the same domain and port as the frontend
  return `${window.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: config.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const post = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };
  return window.fetch(url, options).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

const get = (path) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return window.fetch(url, options).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the order data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Flex with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};

export const getShippingRates = async (toAddress, fromAddress, phone, name, weight, height, width, length, amount) => {
  return post('/api/shipping-rates', { toAddress, fromAddress, phone, name, weight, height, width, length, amount });
}

export const getGeoLocationDataFromZipCode = async (zipcode) => {
  return get(`/api/zipcode-geolocation?zipcode=${zipcode}`)
}

export const getShippingLabel = async (toAddress, fromAddress, phone, name, weight, height, width, length, amount) => {
  return post('/api/shipping-label', { toAddress, fromAddress, phone, name, weight, height, width, length, amount: parseInt(amount, 10) });
}

export const postUpdateReferralInfo = async (currentUserId, transactionId, referralCodeUsed) => {
  return post('/api/update-referral-info', { currentUserId: currentUserId.uuid, transactionId: transactionId.uuid, referralCodeUsed });
}

export const getMetrics = async () => {
  return get('/api/get-metrics')
}

export const addFavoriteEmailToListing = async (id, email, added) => {
  return post('/api/add-favorite-email-to-listing', { id: id.uuid, email, added });
}

export const sendPriceDropEmails = async (id, name, url) => {
  return post('/api/send-price-drop-emails', { id: id.uuid, author_name: name, to_url: url });
}

export const providerDenyOffer = async (id, transition) => {
  return post('/api/provider-deny-offer', { id: id.uuid, transition });
}
export const providerAcceptOffer = async (id, transition, listingId, buyerId) => {
  return post('/api/provider-accept-offer', { id: id.uuid, transition, listingId, buyerId });
}
export const customerFullRefund = async (id, transition, listingId, fromAddress, sellerName, buyerId) => {
  return post('/api/customer-full-refund', { id: id.uuid, transition, listingId: listingId.uuid, fromAddress, sellerName, buyerId });
}

export const acceptOfferFromCustomer = async (id, transition, currentTransaction) => {
  return post('/api/accept-offer', { id: id.uuid, transition, currentTransaction, origin: window.location.origin });
}

export const indicateUserMadeOfferRequest = async (id, madeOffer) => {
  return post('/api/indicate-user-made-offer', { id: id.uuid, madeOffer });
}

export const settleReferralAmount = async (id, listingId) => {
  return post('/api/settle-referral-amount', { id, listingId });
}

export const getSalesTax = async (id, customerAddress, providerAddress, lineItems, listingCategory, listingDescription) => {
  return post('/api/get-sales-tax', { id, customerAddress, providerAddress, lineItems, listingCategory, listingDescription });
}

export const logSalesTaxTransaction = async (id, customerId, customerAddress, providerAddress, listingCategory, listingDescription) => {
  return post('/api/log-transaction', {  id, customerId, customerAddress, providerAddress, listingCategory, listingDescription });
}

export const sendContactMessage = async (params, templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID) => {
  const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
  return await emailjs.send(serviceId, templateId, params, publicKey);
}