// Add here the translations of the country names using key "<language_code>: 'transalation'" e.g. fi: 'Afganistan'
// prettier-ignore
const countryCodes = [
  { code: 'US', en: 'United States', fr: 'États-Unis', es: 'Estados Unidos de América', de: 'Vereinigte Staaten von Amerika' },
  { code: 'UM', en: 'United States Minor Outlying Islands', fr: 'Îles mineures éloignées des États-Unis', es: 'Islas Ultramarinas Menores de Estados Unidos', de: 'United States Minor Outlying Islands' },
];

const getCountryCodes = lang => {
  // Add the lnew locale here so that the correct translations will be returned.
  // If locale is unknown or the translation is missing, this will default to english coutnry name.
  const codes = countryCodes.map(c => {
    const countryName = c[lang] ? c[lang] : c['en'];
    const counryCode = c.code;

    return { code: counryCode, name: countryName };
  });
  return codes;
};

export default getCountryCodes;
