import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes, LINE_ITEM_PROVIDER_COMMISSION, LINE_ITEM_PAYMENT_PROCESSING_FEE } from '../../util/types';

import css from './OrderBreakdown.module.css';
import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;

const LineItemProviderCommissionRefundMaybe = props => {
  const { lineItems, isProvider, intl } = props;

  const refund = lineItems.find(
    item => item.code === LINE_ITEM_PROVIDER_COMMISSION && item.reversal
  );
  const paymentProcessingFee = lineItems.find(
    item => item.code === LINE_ITEM_PAYMENT_PROCESSING_FEE && item.reversal
  );
  let total;
  if (!!paymentProcessingFee) {
    total = new Money(refund.lineTotal.amount + paymentProcessingFee.lineTotal.amount, 'USD');
  }
  else if (!!refund) {
    total = refund.lineTotal;
  }

  return isProvider && refund ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.refundProviderFee" />
      </span>
      <span className={css.itemValue}>{formatMoney(intl, total)}</span>
    </div>
  ) : null;
};

LineItemProviderCommissionRefundMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemProviderCommissionRefundMaybe;
