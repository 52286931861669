import React, { useState } from 'react';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  Form,
  PrimaryButton,
  FieldTextInput,
} from '../../components';
import StaticPage from '../StaticPage/StaticPage';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { Form as FinalForm } from 'react-final-form';

import css from './ContactPage.module.css';
import { FormattedMessage } from 'react-intl';
import { composeValidators, required } from '../../util/validators';
import { sendContactMessage } from '../../util/api';

const ContactPage = () => {
  const [submitted, setSubmitted] = useState(false);

  const submitVerification = async (e) => {
    await sendContactMessage(e);
    setSubmitted(true);
  }

  // prettier-ignore
  return (
    <StaticPage
      title="About Us"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'About Craft Swap',
        name: 'About page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.pageTitle}>Contact the Craft Swap Team</h1>

          <div className={css.contentWrapper}>
            <FinalForm
              initialValues={{}}
              onSubmit={submitVerification}
              render={formRenderProps => {
                const { inProgress, handleSubmit } = formRenderProps;
                const submitInProgress = inProgress;
                const submitDisabled = submitInProgress;

                const form = (
                  <div className={css.root}>
                    <FieldTextInput
                      id="name"
                      name="name"
                      className={css.input}
                      type="text"
                      label={"Name"}
                      placeholder={"Your name"}
                      validate={composeValidators(required("Your name is required."))}
                    />

                    <FieldTextInput
                      id="email"
                      name="email"
                      className={css.input}
                      type="text"
                      label={"Email"}
                      placeholder={"Your email (we'll use this to contact you) "}
                      validate={composeValidators(required("Your email is required."))}
                    />

                    <FieldTextInput
                      id="message"
                      name="message"
                      className={css.input}
                      type="textarea"
                      label={"Message"}
                      placeholder={"Your message"}
                      validate={composeValidators(required("A message is required."))}
                    />

                    <Form onSubmit={handleSubmit}>
                      <div className={css.bottomWrapper}>
                        <PrimaryButton className={css.submitButton} type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                          <FormattedMessage id="ContactForm.submit" />
                        </PrimaryButton>
                      </div>
                    </Form>
                  </div>
                );

                const submittedMesssage = (
                  <div className={css.submittedContainer}>
                    <p>Submitted! We've received your message. Someone from our team will be in contact soon!</p>

                    <a className={css.homePageButton} href={'/'}>Back to Home Page</a>
                  </div>
                )

                return submitted ? submittedMesssage : form;
              }}
            />
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default ContactPage;
