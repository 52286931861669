import React, { createRef, forwardRef, useEffect, useRef, useState } from 'react';
import { bool, func, number, string, oneOfType } from 'prop-types';
import { Form as FinalForm, FormSpy } from 'react-final-form';

import config from '../../../config';
import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { numberAtLeast, required } from '../../../util/validators';

import {
  Form,
  FieldSelect,
  FieldTextInput,
  InlineTextButton,
  PrimaryButton,
  FieldCheckbox,
} from '../../../components';

import EstimatedCustomerBreakdownMaybe from '../EstimatedCustomerBreakdownMaybe';

import css from './ProductOrderForm.module.css';
import SelectOrderTypeComponent from './SelectOrderType/SelectOrderType';


import { types as sdkTypes } from '../../../util/sdkLoader';
const { Money } = sdkTypes;

const renderForm = formRenderProps => {
  const {
    // FormRenderProps from final-form
    handleSubmit,
    form: formApi,

    // Custom props passed to the form component
    intl,
    formId,
    currentStock,
    hasMultipleDeliveryMethods,
    listing,
    listingId,
    isOwnListing,
    onFetchTransactionLineItems,
    onContactUser,
    currentUser,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    values,
    setBuyOffer,
    buyOffer,
    submitFunction,
  } = formRenderProps;

  const handleOnChange = formValues => {
    const { quantity: quantityRaw, deliveryMethod } = formValues.values;
    const quantity = Number.parseInt(quantityRaw, 10);
    const isBrowser = typeof window !== 'undefined';
    const referralDiscount = !!currentUser?.attributes?.profile?.protectedData?.referralDiscountAmount ? currentUser?.attributes?.profile?.protectedData?.referralDiscountAmount : 0;
    const referredBy = !!currentUser?.attributes?.profile?.protectedData?.referredBy;
    if (isBrowser && quantity && deliveryMethod && !fetchLineItemsInProgress && !(quantityRaw === values.quantity && deliveryMethod === values.deliveryMethod)) {
      onFetchTransactionLineItems({
        orderData: { quantity, deliveryMethod, currentUser },
        listingId,
        isOwnListing,
        referralDiscount,
        referredBy
      });
    }
  };

  // In case quantity and deliveryMethod are missing focus on that select-input.
  // Otherwise continue with the default handleSubmit function.
  const handleFormSubmit = e => {
    const { quantity, deliveryMethod } = values || {};
    if (!quantity || quantity < 1) {
      e.preventDefault();
      // Blur event will show validator message
      formApi.blur('quantity');
      formApi.focus('quantity');
    } else if (!deliveryMethod) {
      e.preventDefault();
      // Blur event will show validator message
      formApi.blur('deliveryMethod');
      formApi.focus('deliveryMethod');
    } else {
      e.preventDefault();
      submitFunction({ ...formRenderProps.values, isBuyOffer: buyOffer });
    }
  };


  const breakdownData = {};
  const showBreakdown =
    breakdownData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;
  const breakdown = showBreakdown ? (
    <div className={css.breakdownWrapper}>
      <p>Buy this item now for the price outlined below.</p>
      <h3>
        <FormattedMessage id="ProductOrderForm.breakdownTitle" />
      </h3>
      <EstimatedCustomerBreakdownMaybe
        unitType={config.lineItemUnitType}
        breakdownData={breakdownData}
        lineItems={lineItems}
        listing={listing}
      />
    </div>
  ) : null;

  const makeOfferBreakdown = (
    <div className={css.breakdownWrapper}>
      <h3>
        <FormattedMessage id="ProductOrderForm.breakdownTitle" />
      </h3>
      <EstimatedCustomerBreakdownMaybe
        unitType={config.lineItemUnitType}
        breakdownData={breakdownData}
        lineItems={!!lineItems && lineItems.map(x => {
          if (x.code === "line-item/units" && !!formRenderProps?.values?.offer) {
            return { ...x, lineTotal: new Money(!!values?.quantity ? formRenderProps.values.offer.amount * values.quantity : formRenderProps.values.offer.amount, x.lineTotal.currency), unitPrice: new Money(formRenderProps.values.offer.amount, x.unitPrice.currency) };
          }
          return x;
        })}
        listing={listing}
      />
    </div>
  );

  const showContactUser = typeof onContactUser === 'function';

  const onClickContactUser = e => {
    e.preventDefault();
    onContactUser();
  };

  const contactSellerLink = (
    <InlineTextButton onClick={onClickContactUser}>
      <FormattedMessage id="ProductOrderForm.finePrintNoStockLinkText" />
    </InlineTextButton>
  );
  const quantityRequiredMsg = intl.formatMessage({ id: 'ProductOrderForm.quantityRequired' });

  const hasStock = currentStock && currentStock > 0;
  const quantities = hasStock ? [...Array(currentStock).keys()].map(i => i + 1) : [];
  const hasNoStockLeft = typeof currentStock != null && currentStock === 0;
  const hasOneItemLeft = typeof currentStock != null && currentStock === 1;

  const submitInProgress = fetchLineItemsInProgress;
  const submitEnabled = hasStock && !!formRenderProps?.values?.quantity && !!formRenderProps?.values?.deliveryMethod;

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormSpy subscription={{ values: true }} onChange={handleOnChange} />
      {hasNoStockLeft ? null : hasOneItemLeft ? (
        <FieldTextInput
          id={`${formId}.quantity`}
          className={css.quantityField}
          name="quantity"
          type="hidden"
          validate={numberAtLeast(quantityRequiredMsg, 1)}
        />
      ) : (
        <FieldSelect
          id={`${formId}.quantity`}
          className={css.quantityField}
          name="quantity"
          disabled={!hasStock}
          label={intl.formatMessage({ id: 'ProductOrderForm.quantityLabel' })}
          validate={numberAtLeast(quantityRequiredMsg, 1)}
        >
          <option disabled value="">
            {intl.formatMessage({ id: 'ProductOrderForm.selectQuantityOption' })}
          </option>
          {quantities.map(quantity => (
            <option key={quantity} value={quantity}>
              {intl.formatMessage({ id: 'ProductOrderForm.quantityOption' }, { quantity })}
            </option>
          ))}
        </FieldSelect>
      )}

      {hasNoStockLeft ? null : hasMultipleDeliveryMethods ? (
        <FieldSelect
          id={`${formId}.deliveryMethod`}
          className={css.deliveryField}
          name="deliveryMethod"
          disabled={!hasStock}
          label={intl.formatMessage({ id: 'ProductOrderForm.deliveryMethodLabel' })}
          validate={required(intl.formatMessage({ id: 'ProductOrderForm.deliveryMethodRequired' }))}
        >
          <option disabled value="">
            {intl.formatMessage({ id: 'ProductOrderForm.selectDeliveryMethodOption' })}
          </option>
          <option value={'pickup'}>
            {intl.formatMessage({ id: 'ProductOrderForm.pickupOption' })}
          </option>
          <option value={'shipping'}>
            {intl.formatMessage({ id: 'ProductOrderForm.shippingOption' })}
          </option>
        </FieldSelect>
      ) : (
        <div className={css.deliveryField}>
          <label>{intl.formatMessage({ id: 'ProductOrderForm.deliveryMethodLabel' })}</label>
          <p className={css.singleDeliveryMethodSelected}>
            {values.deliveryMethod === 'shipping'
              ? intl.formatMessage({ id: 'ProductOrderForm.shippingOption' })
              : intl.formatMessage({ id: 'ProductOrderForm.pickupOption' })}
          </p>
          <FieldTextInput
            id={`${formId}.deliveryMethod`}
            className={css.deliveryField}
            name="deliveryMethod"
            type="hidden"
          />
        </div>
      )}
      <FieldCheckbox
        id={"isMakeOffer"}
        name={"isMakeOffer"}
        label={"isMakeOffer"}
        value={buyOffer ? "yes" : "no"}
        className={css.hiddenCheckbox}
      />
      <SelectOrderTypeComponent
        intl={intl}
        buyNowBreakdown={(<>
          {showBreakdown && <>
            {breakdown}
            <div className={css.submitButton}>
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={!submitEnabled} onClick={() => setBuyOffer(true)}>
                {hasStock ? (
                  <FormattedMessage id="ProductOrderForm.ctaButton" />
                ) : (
                  <FormattedMessage id="ProductOrderForm.ctaButtonNoStock" />
                )}
              </PrimaryButton>
            </div>
            <p className={css.finePrint}>
              {hasStock ? (
                <FormattedMessage id="ProductOrderForm.finePrint" />
              ) : showContactUser ? (
                <FormattedMessage id="ProductOrderForm.finePrintNoStock" values={{ contactSellerLink }} />
              ) : null}
            </p>
          </>}
          {!showBreakdown && <>
            <p>Please fill out above information.</p>
          </>}
        </>)}
        makeOfferBreakdown={(<>
          {<>
            {makeOfferBreakdown}
            <div className={css.submitButton}>
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={!submitEnabled} onClick={() => setBuyOffer(false)}>
                {hasStock ? (
                  <FormattedMessage id="ProductOrderForm.ctaButtonMakeOffer" />
                ) : (
                  <FormattedMessage id="ProductOrderForm.ctaButtonNoStock" />
                )}
              </PrimaryButton>
            </div>
            <p className={css.finePrint}>
              {hasStock ? (
                <FormattedMessage id="ProductOrderForm.finePrint" />
              ) : showContactUser ? (
                <FormattedMessage id="ProductOrderForm.finePrintNoStock" values={{ contactSellerLink }} />
              ) : null}
            </p>
          </>}
          {!formRenderProps?.values?.offer && !showBreakdown && <>
            <p>Please fill out above information.</p>
          </>}
        </>)}
        displayMakeOffer={!!formRenderProps?.values?.offer || showBreakdown}
      />
    </Form>
  );
};

const ProductOrderForm = props => {
  const intl = useIntl();

  useEffect(() => {
    const { onFetchTransactionLineItems, currentStock, pickupEnabled, shippingEnabled, currentUser, listingId, isOwnListing, } = props;
    const isBrowser = typeof window !== 'undefined';
    const singleDeliveryMethodAvailableMaybe =
      shippingEnabled && !pickupEnabled
        ? { deliveryMethod: 'shipping' }
        : !shippingEnabled && pickupEnabled
          ? { deliveryMethod: 'pickup' }
          : {};
    const hasMultipleDeliveryMethods = pickupEnabled && shippingEnabled;
    const referralDiscount = !!currentUser?.attributes?.profile?.protectedData?.referralDiscountAmount ? currentUser?.attributes?.profile?.protectedData?.referralDiscountAmount : 0;
    const referredBy = !!currentUser?.attributes?.profile?.protectedData?.referredBy;

    if (isBrowser && currentStock === 1 && !hasMultipleDeliveryMethods) {
      onFetchTransactionLineItems({
        orderData: { quantity: 1, currentUser, ...singleDeliveryMethodAvailableMaybe },
        listingId,
        isOwnListing,
        referralDiscount,
        referredBy
      });
    }
  }, []);;

  const { price, currentStock, pickupEnabled, shippingEnabled, onSubmit } = props;

  const [buyOffer, setBuyOffer] = useState();

  // Should not happen for listings that go through EditListingWizard.
  // However, this might happen for imported listings.
  if (!pickupEnabled && !shippingEnabled) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.noDeliveryMethodSet" />
      </p>
    );
  }

  if (!price) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.listingPriceMissing" />
      </p>
    );
  }
  if (price.currency !== config.currency) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.listingCurrencyInvalid" />
      </p>
    );
  }
  const hasOneItemLeft = currentStock && currentStock === 1;
  const quantityMaybe = hasOneItemLeft ? { quantity: '1' } : {};
  const singleDeliveryMethodAvailableMaybe =
    shippingEnabled && !pickupEnabled
      ? { deliveryMethod: 'shipping' }
      : !shippingEnabled && pickupEnabled
        ? { deliveryMethod: 'pickup' }
        : {};
  const hasMultipleDeliveryMethods = pickupEnabled && shippingEnabled;
  const initialValues = { ...quantityMaybe, ...singleDeliveryMethodAvailableMaybe };

  return (
    <FinalForm
      initialValues={initialValues}
      hasMultipleDeliveryMethods={hasMultipleDeliveryMethods}
      {...props}
      intl={intl}
      render={renderForm}
      setBuyOffer={setBuyOffer}
      buyOffer={buyOffer}
      submitFunction={onSubmit}
    />
  );
};

ProductOrderForm.defaultProps = {
  rootClassName: null,
  className: null,
  price: null,
  currentStock: null,
  listing: null,
  listingId: null,
  isOwnListing: false,
  lineItems: null,
  fetchLineItemsError: null,
};

ProductOrderForm.propTypes = {
  rootClassName: string,
  className: string,

  // form
  formId: string.isRequired,
  onSubmit: func.isRequired,

  // listing
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  listingId: propTypes.uuid,
  price: propTypes.money,
  currentStock: number,
  isOwnListing: bool,

  // line items
  lineItems: propTypes.lineItems,
  onFetchTransactionLineItems: func.isRequired,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // other
  onContactUser: func,
};

export default ProductOrderForm;
