import { parse } from '../../util/urlHelpers';
import { verify } from '../../ducks/EmailVerification.duck';

// ================ Thunks ================ //

export const loadData = (params, search) => {
  const urlParams = parse(search);
  console.log(urlParams)
  const verificationToken = urlParams.t;
  console.log(verificationToken)
  const token = verificationToken ? `${verificationToken}` : null;
  console.log(token)
  return verify(token);
};
