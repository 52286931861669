import React from 'react';
import {
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
} from '../../components';
import StaticPage from '../../containers/StaticPage/StaticPage';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import css from './FAQ.module.css';

const FAQPage = () => {

    // prettier-ignore
    return (
        <StaticPage
            title="About Us"
            schema={{
                '@context': 'http://schema.org',
                '@type': 'AboutPage',
                description: 'About Craft Swap',
                name: 'About page',
            }}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer />
                </LayoutWrapperTopbar>

                <LayoutWrapperMain className={css.staticPageWrapper}>
                    <h1 className={css.pageTitle}>FAQ</h1>

                    <div className={css.contentWrapper}>
                        <div className={css.contentMain}>

                            <h3 className={css.subtitle}>What is the purpose of Craft Swap?</h3>
                            <p>
                                Our mission is to provide crafters and creaters a seamless and quick way to make money by selling new or used surplus crafting supplies, or to purchase supplies from other crafters.
                            </p>

                            <h3 className={css.subtitle}>Why Craft Swap?</h3>
                            <p>
                                Our site provides you an easy and seamless way of listing and selling your surplus crafting supplies. Within minutes, you can have your first product listing up. We also offer to help you out with shipping by generating a label and providing instructions on how to ship the product.
                            </p>

                            <h3 className={css.subtitle}>How do I start selling?</h3>
                            <p>
                                If you don't have an account, you can sign up <a href="/signup" target="_blank">here</a>. Then, navigate to the <a href="/l/draft/00000000-0000-0000-0000-000000000000/new/details" target="_blank">new listing page</a>, fill out the product information, and you're set to start selling your supplies!
                            </p>

                            <h3 className={css.subtitle}>How do I start buying?</h3>
                            <p>
                                If you don't have an account, you can sign up <a href="/signup" target="_blank">here</a>. Then, search for what you're looking for using the search bar at the top of the page. Once you find what you want, select the quantity and the delivery method, and select the "Buy Now" dropdown option. After clicking the "Buy Now" button that appears, you'll be prompted to enter your delivery and payment details. Once you enter these, click the "Confirm and Pay" button. Your purchase will be confirmed, and the seller will ship your product to you!
                            </p>

                            <h3 className={css.subtitle}>Can I choose pickup as a delivery option?</h3>
                            <p>
                                Yes! If you're a seller, make sure to select this option when you create the listing. If you're a buyer, you can select pickup instead of delivery when checking out if the seller offers it.
                            </p>

                            <hr/>

                            <p className={css.reachOut}>If you have any other questions, please reach out to us using our <a href="/contact" target="_blank">contact form</a> and we'll get back to you ASAP!</p>

                        </div>
                    </div>
                </LayoutWrapperMain>

                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </StaticPage>
    );
};

export default FAQPage;
