/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  {
    id: 'product_category',
    label: 'Product Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_product_category'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.


      // * Adhesives & Magnets
      // * Candle Making
      // * Collecting
      // * Fabric
      // * Ribbon
      // * Tie Dye 
      // * Tools & Accessories
      // * Wood & Blank Surfaces
      options: [
        {
          key: 'art_supplies', label: 'Art Supplies', subcategories: [
            { key: 'paint_and_painting', label: 'Paint & Painting Supplies' },
            { key: 'drawing_and_illustration', label: 'Drawing & Illustration Supplies' },
            { key: 'canvas_and_surfaces', label: 'Canvas & Surfaces' },
            { key: 'sculpture_and_modeling', label: 'Sculpture & Modeling' },
            { key: 'art_kits', label: 'Art Kits & Sets' },
            { key: 'drawing_and_coloring_books', label: 'Drawing & Coloring Books' },
            { key: 'resin_art', label: 'Resin Art Supplies' }
          ]
        },
        {
          key: 'jewelry', label: 'Jewelry', subcategories: [
            { key: 'beads', label: 'Beads' },
            { key: 'charms_and_pendants', label: 'Charms & Pendants' },
            { key: 'crystals', label: 'Crystals' },
            { key: 'finished_jewelry', label: 'Finished Jewelry' },
            { key: 'stringing_material', label: 'Stringing Material' },
            { key: 'jewelry_findings', label: 'Jewelry Findings' },
            { key: 'metal_stamping', label: 'Metal Stamping' }
          ]
        },
        {
          key: 'crafts_and_hobbies', label: 'Crafts & Hobbies', subcategories: [
            { key: 'adhesives_and_magnets', label: 'Adhesives & Magnets' },
            { key: 'candle_making', label: 'Candle Making' },
            { key: 'collecting', label: 'Collecting' },
            { key: 'ribbon', label: 'Ribbon' },
            { key: 'scrapbooking', label: 'Scrapbooking' },
            { key: 'tie_dye', label: 'Tie Dye' },
            { key: 'tools_and_accessories', label: 'Tools & Accessories' },
            { key: 'wood_and_blank_surfaces', label: 'Wood & Blank Surfaces' }
          ]
        },
        {
          key: 'craft_machines', label: "Craft Machines", subcategories: [
            { key: 'cricut', label: 'Cricut' },
            { key: 'sublimation', label: 'Sublimation' },
            { key: 'diecutting_machines_tools', label: 'Diecutting Machines & Tools' },
            { key: 'sewing_machines', label: 'Sewing Machines' },
            { key: 'craft_technology', label: 'Craft Technology' },
            { key: 'printers', label: 'Printers' },
            { key: 'lighting', label: 'Lighting' }
          ]
        },
        {
          key: 'fabric', label: 'Fabric', subcategories: [
            { key: 'cotton', label: 'Cotton Fabric' },
            { key: 'home_decor_fabric', label: 'Home Decor Fabric' },
            { key: 'apparel', label: 'Apparel Fabric' },
            { key: 'flannel', label: 'Flannel Fabric' },
            { key: 'fleece', label: 'Fleece Fabric' },
            { key: 'kids', label: 'Kids Fabric' },
            { key: 'fabric_trims', label: 'Fabric Trims' },
            { key: 'quilt', label: 'Quilt Fabric' }
          ]
        },
        {
          key: 'yarn_and_needlework', label: 'Yarn & Needlework', subcategories: [
            { key: 'yarn', label: 'Yarn' },
            { key: 'knitting', label: 'Knitting Supplies' },
            { key: 'crochet', label: 'Crochet Supplies' },
            { key: 'cross_stitch_embroidery', label: 'Cross Stitch & Embroidery' },
            { key: 'felting', label: 'Felting' },
            { key: 'latch_locker_hooking', label: 'Latch & Locker Hooking' },
            { key: 'thread_and_bobbins', label: 'Thread & Bobbins' }
          ]
        },
        { key: 'floral', label: 'Floral', subcategories: [
            { key: 'floral_arrangements', label: 'Floral Arrangements' },
            { key: 'wedding_floral', label: 'Wedding Floral' },
            { key: 'vases_and_containers', label: 'Vases & Containers' },
            { key: 'greenery', label: 'Greenery' },
            { key: 'garlands_and_wreaths', label: 'Garlands & Wreaths' },
            { key: 'floral_supplies', label: 'Floral Supplies' }
        ] },
        { key: 'home_and_decor', label: 'Home & Decor', subcategories: [
            { key: 'home_decor', label: 'Home Decor' },
            { key: 'picture_frames', label: 'Picture Frames' },
            { key: 'candles_and_warmers', label: 'Candles & Warmers' },
            { key: 'kitchen_decor', label: 'Kitchen Decor' },
            { key: 'bed_bath_decor', label: 'Bed & Bath Decor' },
            { key: 'home_office', label: 'Home Office' },
            { key: 'pet_supplies', label: 'Pet Supplies' }
        ] },
        { key: 'office_supplies', label: 'Office Supplies', subcategories: [
            { key: 'calendars_and_planners', label: 'Calendars & Planners' },
            { key: 'office_supplies', label: 'Office Supplies' },
            { key: 'whiteboards_and_corkboards', label: 'Whiteboards & Corkboards' },
            { key: 'pens_pencils_markers', label: 'Pens, Pencils, & Markers' },
            { key: 'break_room_supplies', label: 'Break Room Supplies' }
        ] },
        { key: 'storage_and_organization', label: 'Storage & Organization', subcategories: [
            { key: 'craft_room', label: 'Craft Room Storage' },
            { key: 'sewing_room', label: 'Sewing Room Storage' },
            { key: 'baskets', label: 'Baskets' },
            { key: 'plastic', label: 'Plastic Storage' },
            { key: 'kitchen', label: 'Kitchen Storage' },
            { key: 'kids_room', label: "Kid's Room Storage" },
            { key: 'bathroom', label: 'Bathroom Storage' },
            { key: 'laundry', label: 'Laundry Storage' }
        ] },
        { key: 'papercraft', label: 'Papercraft', subcategories: [
            { key: 'paper', label: 'Paper' },
            { key: 'card_making', label: 'Card Making' },
            { key: 'paper_craft_tools', label: 'Paper Craft Tools' },
            { key: 'albums', label: 'Albums' },
            { key: 'glue_and_adhesives', label: 'Glue & Adhesives' },
            { key: 'embellishments_and_stickers', label: 'Embellishments & Stickers' },
            { key: 'embossing', label: 'Embossing' }
        ] },
        { key: 'seasonal', label: 'Seasonal', subcategories: [
            { key: 'halloween', label: 'Halloween' },
            { key: 'christmas', label: 'Christmas' },
            { key: 'day_of_the_dead', label: 'Day of the Dead' },
            { key: 'patriotic', label: 'Patriotic' },
            { key: 'summer', label: 'Summer' },
            { key: 'spring', label: 'Spring' },
            { key: 'wedding', label: 'Wedding' },
            { key: 'birthday', label: 'Birthday' },
            { key: 'baby_shower', label: 'Baby Shower' }
        ] },
        { key: 'other', label: 'Other' },
      ],
    },
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },

  {
    id: 'condition',
    label: 'Condition',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_condition'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'new', label: 'New' },
        { key: 'used_like_new', label: 'Used - Like New' },
        { key: 'used_very_good', label: 'Used - Very Good' },
        { key: 'used_good', label: 'Used - Good' },
        { key: 'used_acceptable', label: 'Used - Acceptable' },
      ],
    },
  },


  {
    id: 'product_status',
    label: 'Product Status',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_product_status'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'supplies', label: 'Only Supplies' },
        { key: 'in_progress', label: 'In Progress' },
        { key: 'finished', label: 'Finished' },
      ],
    },
  },

  {
    id: 'smoking',
    label: 'Smoking?',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_smoking'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'yes', label: 'Yes' },
        { key: 'no', label: 'No' },
        { key: 'n/a', label: 'N/A' },
      ],
    },
  },

  {
    id: 'pets',
    label: 'Pets?',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_pets'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'yes', label: 'Yes' },
        { key: 'no', label: 'No' },
        { key: 'n/a', label: 'N/A' },
      ],
    },
  },

  // Here is an example of multi-enum search filter.
  //
  // {
  //   id: 'colors',
  //   label: 'Colors',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_amenities'],
  //   config: {
  //     // Schema type options: 'enum', 'multi-enum'
  //     // Both types can work so that user selects multiple values when filtering search results.
  //     // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
  //     // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
  //     schemaType: 'multi-enum',

  //     // Optional modes: 'has_all', 'has_any'
  //     // Note: this is relevant only for schema type 'multi-enum'
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
  //     searchMode: 'has_all',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'towels', label: 'Towels' },
  //       { key: 'bathroom', label: 'Bathroom' },
  //       { key: 'swimming_pool', label: 'Swimming pool' },
  //       { key: 'barbeque', label: 'Barbeque' },
  //     ],
  //   },
  // },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Relevance key is used with keywords filter.
  // Keywords filter also sorts results according to relevance.
  relevanceFilter: 'keywords',

  // Keyword filter is sorting the results by relevance.
  // If keyword filter is active, one might want to disable other sorting options
  // by adding 'keyword' to this list.
  conflictingFilters: [],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const listing = {
  // These should be listing details from public data with schema type: enum
  // SectionDetailsMaybe component shows these on listing page.
  enumFieldDetails: ['product_category', 'brand', 'condition', 'pets', 'smoking', 'purpose', 'product_status'],
};
